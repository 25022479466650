import { Link } from "react-router-dom";

function Product(props) {
 const {prdname,price,id} =props

    return ( 
  
      <tr>
        <td>{prdname}</td>
        <td>{price}</td>
        <td><Link to={`/products/${id}`}>more details</Link></td>
      </tr>
    
    );
}

export default Product;