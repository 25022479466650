import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Product from './Components/Product';
import Alproducts from './Components/Alproducts';
import { Link } from 'react-router-dom';
import Home from './Components/Home';
import More from './Components/more';
import Error from './Components/Error';

function App() {
  return (
    <div className="App">
 {/* <More /> */}
      <h1><Link to="/products">Go to product page</Link></h1>
      <h1><Link to="/">Go to home page</Link></h1>

      <Routes>
         <Route path='/' element={<Home/>}></Route> 
        <Route  path='/products' element={<Alproducts/>}></Route>
        <Route  path='/products/:id' element={<More/>}></Route> 
         <Route path="*" element={<Error/>}></Route>
      </Routes>
    </div>
  );
}

export default App;
