import { useEffect } from "react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Product from "./Product";

function get({page},text){
    return fetch(`http://localhost:3004/Products?_limit=2&_page=${page}&q=${text}`).then((res)=>res.json())
}


function getcurrpage(value){
    value= +(value)
    if( typeof value==="number" && value<1){
        value=1
    }
   if(!value){
    value=1
   }
   return value
}

const initaltext={
    search:""
}


///main function starts
function Alproducts() {
    
    const [params,setsearchparams] = useSearchParams()
    const initialpage= getcurrpage(params.get("page") ) //Number(params.get("page")||1)
   
     const [text,setText]=useState(initaltext)
   
    const [data,setdata]= useState([])
    const [page,setpage] = useState(initialpage)
    

    useEffect(()=>{
      handelget()
      setsearchparams({page})
    },[page])



 const handletext=(e)=>{
    const{name,value}=e.target
    setText({...text,[name]:value})
    // const {search}= text
    handelget()
 }
 //console.log(text.search)

   const handelget=()=>{
    const {search}=text
      get({page},search).then((res)=>{
         setdata(res)
      })
   }



    return (
        <> 
        <table>
        <thead>
            <tr>
                <th>Name</th>
                <th>price</th>
                <th>more</th>
            </tr>
        </thead>
        <tbody>
         {data && data.map((el)=>{
            return <Product key={el.id} prdname={el.name} price={el.price} id= {el.id}  />
         })}
        </tbody>
    </table> 
    <button disabled={page===1}onClick={()=>setpage(page-1)}>prev</button>
    <button>{page}</button>
    <button disabled={page===2}onClick={()=>setpage(page+1)}>next</button>;
    <br></br>
    <br></br>
    <input name="search" value={text.search} onChange={handletext}
    type="text" placeholder="search.."></input>
    <button onClick={handelget}>submit</button>
    </>
    )
}

export default Alproducts;